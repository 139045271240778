import React, { useEffect, useState } from 'react';
import { Modal, Button, Spinner, Form, Table } from 'react-bootstrap';
import { postRequest } from '../../../consts/Server/Requests';
import { URL_EndPoints } from '../../../consts/Server/URL_EndPoints';
import { adminToastRunner } from '../../admins/adminUtils';
import { toast } from 'react-toastify';
import IsLoading from '../../../consts/IsLoading/IsLoading';

const ViewReturnTask = ({ show, productData, handleClose, modalLoader, taskStatus }: any) => {

  const [selectedItems, setSelectedItems] = useState<any[]>(productData || []);
  const [apiLoader, setApiLoader] = useState<any>(false);

  useEffect(() => {
    setSelectedItems(productData)
  }, [productData?.id])

  const handleQuantityChange = (index: number, value: number, keyName: any) => {
    const updatedItems = [...selectedItems];
    updatedItems[index][keyName] = !value ? value : Number(value);
    setSelectedItems(updatedItems);
  };

  const submitHandler = async () => {

    setApiLoader(true)
    if (!selectedItems || selectedItems.length === 0) {
      toast.warning('No Item Is Selected', { position: "top-center" });
      setApiLoader(false)
      return;
    }

    const initialValue = {
      returnedItems: selectedItems?.map((ele: any) => ({
        id: ele?.id,
        condition: ele?.condition,
        age: ele?.age,
        care: ele?.care,
      }))
    }

    const response = await postRequest(URL_EndPoints()?.acceptReturnedItems, initialValue, setApiLoader)
    adminToastRunner(response)
  }


  return (
    <Modal show={show} onHide={handleClose} size="xl"
      dialogClassName="modal-90w"
    >
      <Modal.Header closeButton>
        <Modal.Title>Returned Materials</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        {<div>
          <Table striped bordered hover responsive>
            <thead style={{ backgroundColor: '#5cb85c', color: 'white' }} className='px-2'>
              <tr className='px-2'>
                <th><strong className='mx-1'>Item</strong></th>
                <th><strong>Quantity</strong></th>
                <th><strong>Condition</strong></th>
                <th><strong>Age</strong></th>
                <th><strong>Care</strong></th>
              </tr>
            </thead>
            {
              modalLoader ? (
                <div style={{ position: 'absolute', top: '100%', left: '50%', transform: 'translate(-50%, -50%)' }}>
                  <IsLoading />
                </div>
              ) : <tbody>
                {selectedItems.map((item, index) => (
                  <tr key={index} className='px-2'>
                    <td className='mx-1'>{item?.item?.title} </td>
                    <td>{item?.quantity}</td>
                    <td>
                      <Form.Control
                        as="select"
                        name="condition"
                        value={item?.condition}
                        onChange={(e: any) => handleQuantityChange(index, e.target.value, "condition")}
                        required
                      >
                        <option value="">Select Condition</option>
                        {taskStatus?.cat_1?.map((ele: any) => (
                          <option key={ele.id} value={ele.id}>
                            {ele.name}
                          </option>
                        ))}
                      </Form.Control>
                    </td>
                    <td>
                      <Form.Control
                        as="select"
                        name="age"
                        value={item?.age}
                        onChange={(e: any) => handleQuantityChange(index, e.target.value, "age")}
                        required
                      >
                        <option value="">Select Age</option>
                        {taskStatus?.cat_2?.map((ele: any) => (
                          <option key={ele.id} value={ele.id}>
                            {ele.name}
                          </option>
                        ))}
                      </Form.Control>
                    </td>
                    <td>
                      <Form.Control
                        as="select"
                        name="care"
                        value={item?.care}
                        onChange={(e: any) => handleQuantityChange(index, e.target.value, "care")}
                        required
                      >
                        <option value="">Select Care</option>
                        {taskStatus?.cat_3?.map((ele: any) => (
                          <option key={ele.id} value={ele.id}>
                            {ele.name}
                          </option>
                        ))}
                      </Form.Control>
                    </td>
                  </tr>
                ))}
              </tbody>
            }

          </Table>
        </div>}
      </Modal.Body>
      <Modal.Footer>
        {
          productData?.length > 0 && <Button disabled={apiLoader} variant="secondary" onClick={submitHandler}>
            {apiLoader ? "Saving" : " Submit"}
          </Button>
        }
      </Modal.Footer>
    </Modal>
  );
};

export default ViewReturnTask;
